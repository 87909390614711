<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-title class="error-title text-xl-h1 text-xs-h6 text-md-h4 text-lg-h3 text-sm-h5">500 Internal Server Error</v-card-title>
          <v-card-text>
            <p class="text-xl-h3 text-xs-h6 text-md-h6 text-lg-h6 text-sm-subtitle-1">Oops! Something went wrong on our end.</p>
            <p class="text-xl-h6 text-xs-h6 text-md-h6 text-lg-h6 text-sm-subtitle-1">We're sorry for the inconvenience. Please try again later.</p>
            <p class="text-xl-h6 text-xs-h6 text-md-h6 text-lg-h6 text-sm-subtitle-1">If the issue persists, contact support for assistance.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
}
</script>

<style scoped>
.error-card {
  max-width: 600px;
  margin: 50px auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.error-title {
  color: #ff5757;
  font-size: 24px;
  font-weight: bold;
}

/* Add more styles as needed */
</style>
